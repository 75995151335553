<template>
	<PageLayout :title="$t('deposit.title')">
		<div>
			<form id="advancedCashRedir" action="https://wallet.advcash.com/sci/" method="post">
				<input type="hidden" name="ac_account_email" value="info@gfmarketsllc.com" />
				<input type="hidden" name="ac_sci_name" value="Cashier" />
				<input id="formAmount" type="hidden" name="ac_amount" />
				<input type="hidden" name="ac_currency" value="USD" />
				<input id="formTransactionId" type="hidden" name="ac_order_id" />
				<input id="formSignature" type="hidden" name="ac_sign" />
				<input id="formPaymentUrl" type="hidden" name="ac_success_url" />
				<input type="hidden" name="ac_success_url_method" value="GET" />
				<input id="formNoPaymentUrl" type="hidden" name="ac_fail_url" />
				<input type="hidden" name="ac_fail_url_method" value="GET" />
				<input id="statusUrl" type="hidden" name="ac_status_url" />
				<input type="hidden" name="ac_status_url_method" value="GET" />
				<input type="hidden" name="AVAILABLE_PAYMENT_METHODS" value="account, voucher, sms, wire" />
			</form>
			<div class="cashier-box cashier-box--deposit">
				<payment-logo :custom-logo="'advancedcash.svg'" />

				<div class="small-width overflow-visible">
					<payment-wallet />
				</div>

				<div class="line-separator-wrapper">
					<div class="line-separator" />
				</div>

				<amount-buttons
					:buttons-value="currentMethod.predefinedDepositAmounts"
					:form-amount-value="form.amount || currentMethod.predefinedDepositAmounts[0]"
					:currency="'USD'"
					:form-errors="formErrors['amount']"
					:current-method="currentMethod"
					@set-button-value="setButtonValue"
					@input-amount-value-validation="setFormValidation"
				/>

				<div class="small-width">
					<promocode
						:form-promo-value="form.promocode"
						:form-error="formErrors['promocode']"
						@set-promo-value="setPromoValue"
					/>
				</div>

				<button type="button" class="button primary-button deposit center" :disabled="!form.validated" @click="pay()">
					{{ $t('deposit.top-up') }}
				</button>
				<div v-if="loading" class="loader-wrapper">
					<loader />
				</div>
			</div>
		</div>
	</PageLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import PageLayout from '@/components/page-layout/PageLayout';
import apiClient from '../../../../api';
import Analytics from '@/utils/analytics';

const loader = () => import('../../../../shared/loader/Loader.vue');
const paymentLogo = () => import('../../../../shared/payment-logo/PaymentLogo');
const amountButtons = () => import('../../../../shared/amount-buttons/AmountButtons');
const promocode = () => import('../../../../shared/promocode/Promocode');
const paymentWallet = () => import('../../../../shared/payment-wallet/PaymentWallet');

export default {
	name: 'DepositAdvancedCash',
	components: {
		PageLayout,
		loader,
		paymentLogo,
		amountButtons,
		promocode,
		paymentWallet,
	},
	data() {
		return {
			cashierUrl: null,
			cashierKey: null,
			cashierToken: null,
			form: {
				amount: null,
				promocode: null,
				validated: true,
			},
			formErrors: {
				amount: null,
				promocode: null,
			},
			loading: false,
			paymentDone: false,
			walletId: null,
		};
	},
	computed: {
		...mapGetters(['isEmbedded']),
		currentMethod() {
			return this.$store.getters.getCurrentMethod('advancedcash');
		},
	},
	async mounted() {
		await this.getUserWallet();
	},
	methods: {
		async getUserWallet() {
			try {
				const { id } = await apiClient.fetchFullWallet(this.$route.params.walletId);
				this.walletId = id;
			} catch (e) {
				this.error = 'wallets.failed-to-load-wallet';
			} finally {
				this.loading = false;
			}
		},
		setButtonValue(event) {
			this.form.amount = event;
		},
		setPromoValue(event) {
			this.form.promocode = event;
		},
		setFormValidation(event) {
			this.form.validated = event;
		},
		async createTransaction(amount, currency) {
			const form = document.getElementById('advancedCashRedir');
			const formData = new FormData(form);
			const params = new URLSearchParams(formData);
			const response = await apiClient.advancedCashCreateTransaction(
				amount,
				currency,
				this.walletId,
				params.toString()
			);

			if (response.status === 201) {
				return response.data;
			}
			throw new Error('Invalid response from create transaction');
		},
		async pay() {
			this.loading = true;

			try {
				const currency = 'USD';
				const result = await this.createTransaction(this.form.amount, currency);

				Analytics.trackEvent({
					event: 'Deposit',
					value: this.form.amount,
					label: 'PerfectMoney',
				});

				const redirectBase = `${window.location.origin}/payments/deposit`;

				const form = document.getElementById('advancedCashRedir');
				document.getElementById('formAmount').value = this.form.amount;
				document.getElementById('formTransactionId').value = result.transactionId;
				document.getElementById('formPaymentUrl').value = `${redirectBase}/advancedmoney_success`;
				document.getElementById('formNoPaymentUrl').value = `${redirectBase}/advancedmoney_failed`;
				document.getElementById('formSignature').value = result.sign;
				document.getElementById('statusUrl').value = `${this.backendUrl}/advancedmoney_success`;

				if (this.isEmbedded) {
					form.setAttribute('target', '_blank');
					this.$router.push({
						path: '/payments/deposit/confirmation',
						query: { status: 'external' },
					});
				}

				form.submit();
			} catch (e) {
				const walletId = this.$route.params.walletId;
				this.$router.push({
					path: `/payments/deposit/${walletId}/confirmation`,
					query: { status: 'error' },
				});
				console.error('Failed to fetch user data', e);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
<style scoped>
#perfectmoneyRedir {
	visibility: hidden;
}
</style>
